import { TENANT_ID } from 'global/constants';
import { NE_EI } from './EmailInput';
import { NE_FD } from './FormData';

const EnergyMainGasGuest = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFields : {}),
  ...(tenantId === TENANT_ID.AVA ? NE_FD.base.AVAGuestOnlyFields : {}),
  experts: NE_FD.experts.EnergyMainGasGuest(tenantId),
  ...NE_EI,
});

const EnergyGuestGasMain = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFields : {}),
  experts: NE_FD.experts.EnergyGuestGasMain(tenantId),
  ...NE_EI,
});

const GasMain = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFields : {}),
  experts: NE_FD.experts.GasMainOwner(tenantId),
  ...NE_EI,
});

const EnergyMain = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFields : {}),
  experts: NE_FD.experts.EnergyMainOwner(tenantId),
  ...NE_EI,
});

const GasGuest = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.AVA ? NE_FD.base.AVAGuestOnlyFields : {}),
  experts: NE_FD.experts.GasGuest(tenantId),
  ...NE_EI,
});

const EnergyGuest = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.AVA ? NE_FD.base.AVAGuestOnlyFields : {}),
  experts: NE_FD.experts.EnergyGuest(tenantId),
  ...NE_EI,
});
const EnergyGuestGasGuest = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.AVA ? NE_FD.base.AVAGuestOnlyFields : {}),
  experts: [
    ...NE_FD.experts.EnergyGuest(tenantId),
    ...NE_FD.experts.GasGuest(tenantId),
    ...NE_FD.experts.EnergyGuestGasGuest(tenantId),
  ],
  ...NE_EI,
});
const EnergyMainHilfsbetrieb = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFieldsHilfsbetrieb : {}),
  experts: [
    ...NE_FD.experts.EnergyMainNoOwner(tenantId),
    ...NE_FD.experts.EnergyMainOwner(tenantId),
  ],
  ...NE_EI,
});

const EnergyMainHilfsbetriebGasGuestHauptbetrieb = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFieldsHilfsbetrieb : {}),
  ...(tenantId === TENANT_ID.AVA ? NE_FD.base.AVAGuestOnlyFields : {}),
  experts: [
    ...NE_FD.experts.EnergyMainGasGuest(tenantId),
    // ...NE_FD.experts.EnergyMainNoOwner(tenantId),
    // ...NE_FD.experts.GasGuest(tenantId),
    // ...NE_FD.experts.EnergyMainOwner(tenantId),
  ],
  ...NE_EI,
});

const GasMainHilfsbetrieb = (tenantId) => ({
  ...NE_FD.base.baseFormData,
  ...(tenantId === TENANT_ID.BAG ? NE_FD.base.BAGMainFieldsHilfsbetrieb : {}),
  experts: [...NE_FD.experts.GasMainNoOwner(tenantId), ...NE_FD.experts.GasMainOwner(tenantId)],
  ...NE_EI,
});

const NE_FD_CFG = {
  EnergyMainGasGuest,
  GasGuest,
  GasMain,
  EnergyGuest,
  EnergyMainHilfsbetrieb,
  EnergyGuestGasMain,
  EnergyMain,
  GasMainHilfsbetrieb,
  EnergyMainHilfsbetriebGasGuestHauptbetrieb,
  EnergyGuestGasGuest,
};

export { NE_FD_CFG };
