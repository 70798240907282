import { TENANT_ID } from 'global/constants';

const baseFormData = {
  companyName1: 'Test',
  companyName2: 'Gmbh',
  companyName3: '',
  companyOwner: 'Test Owner',
  companyStreet: 'Test Street',
  companyHouseNumber: '12',
  companyPostal: '12121',
  companyCity: 'Teststadt',
  companyRegion: '',
  gridProviderEnergy: 'Strom Test provider',
  gridProviderGas: 'Gas Test provider',
  foreignCompanyIdEnergy: '12123sd12',
  foreignCompanyIdGas: '12123sd12',
  phone: '121231231',
  mobile: '',
  energyAgreement: true,
  gasAgreement: true,
  doesNeedAvaGuestPostal: false,
  avaGuestPostal: '',
};

const BAGMainFields = {
  craftsChamberArea: 'Schwaben',
  craftsChamberNumber: '98031',
  craftsChamberDate: '12.12.2000',
  businessRegistrationLocation: 'Testlocal',
  businessRegistrationDate: '12.12.2000',
  commercialRegisterNumber: '',
  commercialRegisterDate: '',
};

const BAGMainFieldsHilfsbetrieb = {};

const AVAGuestOnlyFields = {
  doesNeedAvaGuestPostal: true,
  avaGuestPostal: '12345',
};

const GasGuest = (tenantId) => [
  {
    lastName: 'Nachnacme',
    firstName: 'Vorname',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '12.12.1200' : undefined,
    energyValidityDate: '',
    gasValidityDate: '12.12.3040',
    id: 'GasGuest_1',
  },
];

const GasMainNoOwner = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Test Vorname',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '23.02.2000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: false,
    id: 'GasMainNoOwner_1',
    firstQualification: 'masterFrom2004Gas',
    secondQualification: 'masterFrom2004Safety50Plus',
  },
];
const EnergyMainNoOwner = (tenantId) => [
  {
    lastName: 'No Owner',
    firstName: 'Test Vorname',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? '23.02.2000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: false,
    id: 'EnergyMainNoOwner_1',
    firstQualification: 'masterFrom2004Energy',
    secondQualification: 'masterFrom2004Safety50MinusEnergy',
  },
];

const EnergyMainOwner = (tenantId) => [
  {
    lastName: 'Owner',
    firstName: 'Test Vorname',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? '23.02.2000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: true,
    id: 'EnergyMainOwner_1',
    firstQualification: 'masterFrom1998To2003Energy',
    secondQualification: 'masterFrom1998To2003Electrician',
  },
];

const GasMainOwner = (tenantId) => [
  {
    lastName: 'Owner',
    firstName: 'Test Vorname',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '23.02.2000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: true,
    id: 'GasMainOwner_1',
    firstQualification: 'masterFrom1998To2003Gas',
    secondQualification: 'masterFrom1998To2003GasWater',
  },
];

const EnergyMainGasGuest = (tenantId) => [
  {
    lastName: 'NoOwner',
    firstName: 'Test Vorname',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '23.02.2000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '23.10.2300',
    isExpertOwner: false,
    id: 'EnergyMainGasGuest_1',
    firstQualification: 'masterFrom2004Energy',
    secondQualification: 'masterFrom2004Safety50MinusEnergy',
  },
  {
    lastName: 'Gas',
    firstName: 'Test',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '20.12.1000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '23.10.2300',
    id: 'EnergyMainGasGuest_2',
  },
];

const EnergyGuest = (tenantId) => [
  {
    lastName: 'Nachnacme',
    firstName: 'Vorname',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? '12.12.1200' : undefined,
    energyValidityDate: '12.12.3040',
    gasValidityDate: '',
    id: 'EnergyGuest_1',
  },
  {
    lastName: 'Nachnacme2',
    firstName: 'Vorname2',
    isExpertEnergyActive: true,
    isExpertGasActive: false,
    birthdate: tenantId === TENANT_ID.BAG ? '12.12.1200' : undefined,
    energyValidityDate: '12.12.3040',
    gasValidityDate: '',
    id: 'EnergyGuest_2',
  },
];
const EnergyGuestGasMain = (tenantId) => [
  {
    lastName: 'Owner Gas',
    firstName: 'Test Vorname',
    isExpertEnergyActive: false,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '23.02.2000' : undefined,
    energyValidityDate: '',
    gasValidityDate: '',
    isExpertOwner: true,
    id: 'EnergyGuestGasMain_1',
    firstQualification: 'masterFrom1998To2003Gas',
    secondQualification: 'masterFrom1998To2003GasWater',
  },
  ...EnergyGuest(tenantId),
];
const EnergyGuestGasGuest = (tenantId) => [
  {
    lastName: 'Nachnacme',
    firstName: 'Vorname',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '12.12.1200' : undefined,
    energyValidityDate: '12.12.3040',
    gasValidityDate: '12.12.3040',
    id: 'EnergyGuest_1',
  },
  {
    lastName: 'Nachnacme2',
    firstName: 'Vorname2',
    isExpertEnergyActive: true,
    isExpertGasActive: true,
    birthdate: tenantId === TENANT_ID.BAG ? '12.12.1200' : undefined,
    energyValidityDate: '12.12.3040',
    gasValidityDate: '12.12.3040',
    id: 'EnergyGuest_2',
  },
];
const NE_FD = {
  base: { baseFormData, BAGMainFields, BAGMainFieldsHilfsbetrieb, AVAGuestOnlyFields },
  experts: {
    GasGuest,
    EnergyMainGasGuest,
    EnergyGuest,
    EnergyMainNoOwner,
    EnergyMainOwner,
    GasMainOwner,
    EnergyGuestGasMain,
    GasMainNoOwner,
    EnergyGuestGasGuest,
  },
};

export { NE_FD };
