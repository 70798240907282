import { isRegisteringInstallerOnlyHilfsbetrieb } from 'utils/InstallerUtils';
import {
  NE_DOCUMENTS_FIELDS,
  NE_EXPERTS_DOCUMENTS_FIELDS,
} from 'components/NEForm/Utils/DocumentsFields';
import { getInstallerEntryByExpert } from 'components/NEForm/Utils/NEDataUtils';
import { FORM_CONSTANTS, VALIDATION_CONSTANTS } from 'global/constants';
import { useMemo } from 'react';
import { getTenantId } from 'utils/tenantUtil';
import * as Yup from 'yup';
import { evaluateCondition } from 'global/fieldValidationFunction';
import { FIRST_QUALIFICATIONS, SECOND_QUALIFICATIONS } from 'global/qualifications';

export const useNEFormUploadIV = (valuesFirstPage, installerEntry, branchSelectionValues) => {
  const calculateExpertTriggers = (experts, branchSelectionValues) => {
    let expertTriggers = {};
    experts.forEach((expert) => {
      let expertQualificationsTriggers = [];
      if (expert.firstQualificationEnergy) {
        expertQualificationsTriggers.push(
          FIRST_QUALIFICATIONS[expert.firstQualificationEnergy].triggerID
        );
      }
      if (expert.firstQualificationGas) {
        expertQualificationsTriggers.push(
          FIRST_QUALIFICATIONS[expert.firstQualificationGas].triggerID
        );
      }
      if (expert.secondQualificationEnergy) {
        expertQualificationsTriggers.push(
          SECOND_QUALIFICATIONS[expert.secondQualificationEnergy].triggerID
        );
      }
      if (expert.secondQualificationGas) {
        expertQualificationsTriggers.push(
          SECOND_QUALIFICATIONS[expert.secondQualificationGas].triggerID
        );
      }
      if (expert.isExpertOwner) {
        expertQualificationsTriggers.push(40);
      }
      expert['expertQualificationsTriggers'] = expertQualificationsTriggers;
      const evaluateConditionsConfig = {
        branchSelectionValues,
        isOnlyHilfsbetrieb: isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
        form: FORM_CONSTANTS.NE,
        expertQualificationsTriggers,
        expert,
      };
      const triggers = NE_EXPERTS_DOCUMENTS_FIELDS[
        getInstallerEntryByExpert(expert, branchSelectionValues)
      ][getTenantId()]
        .filter((field) =>
          field.whenShouldFieldRender
            ? evaluateCondition(field.whenShouldFieldRender, evaluateConditionsConfig)
            : true
        )
        .map((field) => field.id); //TODO: refactor
      expertTriggers[expert.id] = triggers;
    });
    return expertTriggers;
  };

  const calculateDocumentTriggers = (installerEntry, branchSelectionValues) => {
    const evaluateConditionsConfig = {
      branchSelectionValues,
      isOnlyHilfsbetrieb: isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
      form: FORM_CONSTANTS.NE,
    };

    let triggers = NE_DOCUMENTS_FIELDS[installerEntry][getTenantId()]
      .filter((field) =>
        field.whenShouldFieldRender
          ? evaluateCondition(field.whenShouldFieldRender, evaluateConditionsConfig)
          : true
      )
      .map((field) => field.id);

    return triggers;
  };

  const createInitialValues = (experts, documentTriggerIds, expertTriggers) => {
    const initialValues = {
      experts: experts,
      documentsTrigger: documentTriggerIds,
      expertTrigger: expertTriggers,
      isAddressPublic: false,
      canSendMail: false,
      changes: '',
    };

    return initialValues;
  };

  const createValidationSchema = (
    initialValues,
    installerEntry,
    expertTriggers,
    experts,
    branchSelectionValues
  ) => {
    let validationObject = {};

    const evaluateConditionsConfig = {
      branchSelectionValues,
      isOnlyHilfsbetrieb: isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
      form: FORM_CONSTANTS.NE,
    };
    NE_DOCUMENTS_FIELDS[installerEntry][getTenantId()]
      .filter((section) =>
        section.whenShouldFieldRender
          ? evaluateCondition(section.whenShouldFieldRender, evaluateConditionsConfig)
          : true
      )
      .forEach((section) => {
        validationObject[section.name] = Yup.array().when([], {
          is: () => {
            return evaluateCondition(section.isOptional, {
              isOnlyHilfsbetrieb: isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
              form: FORM_CONSTANTS.NE,
            });
          },
          then: Yup.array(),
          otherwise: Yup.array().min(1).required(),
        });

        initialValues[section.name] = [];
      });

    Object.keys(expertTriggers).map((key) => {
      let expertValidation = {};
      const expert = experts.find((expert) => expert.id === key);
      let expertQualificationsTriggers = [];
      if (expert.firstQualificationEnergy) {
        expertQualificationsTriggers.push(
          FIRST_QUALIFICATIONS[expert.firstQualificationEnergy].triggerID
        );
      }
      if (expert.firstQualificationGas) {
        expertQualificationsTriggers.push(
          FIRST_QUALIFICATIONS[expert.firstQualificationGas].triggerID
        );
      }
      if (expert.secondQualificationEnergy) {
        expertQualificationsTriggers.push(
          SECOND_QUALIFICATIONS[expert.secondQualificationEnergy].triggerID
        );
      }
      if (expert.secondQualificationGas) {
        expertQualificationsTriggers.push(
          SECOND_QUALIFICATIONS[expert.secondQualificationGas].triggerID
        );
      }
      if (expert.isExpertOwner) {
        expertQualificationsTriggers.push(40);
      }
      const evaluateConditionsConfig = {
        branchSelectionValues,
        isOnlyHilfsbetrieb: isRegisteringInstallerOnlyHilfsbetrieb(branchSelectionValues),
        form: FORM_CONSTANTS.NE,
        expertQualificationsTriggers,
        expert,
      };
      initialValues[key] = {};

      NE_EXPERTS_DOCUMENTS_FIELDS[getInstallerEntryByExpert(expert, branchSelectionValues)][
        getTenantId()
      ]
        .filter((field) =>
          field.whenShouldFieldRender
            ? evaluateCondition(field.whenShouldFieldRender, evaluateConditionsConfig)
            : true
        )
        .forEach((section) => {
          expertValidation = {
            ...expertValidation,
            [section.name]: Yup.array().when([], {
              is: () => {
                return section.isOptional
                  ? evaluateCondition(section.isOptional, { evaluateConditionsConfig })
                  : true;
              },
              then: Yup.array(),
              otherwise: Yup.array().min(1).required(),
            }),
          };
          initialValues[key][section.name] = [];
        });
      validationObject[key] = Yup.object().shape(expertValidation);
    });

    const validationSchema = Yup.object().shape({
      changes: Yup.string()
        .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
        .max(FORM_CONSTANTS.FREETEXT_INPUT, VALIDATION_CONSTANTS.TOO_LONG),
      isAddressPublic: Yup.bool(),
      canSendMail: Yup.bool(),
      ...validationObject,
    });

    return validationSchema;
  };

  return useMemo(() => {
    if (
      !installerEntry ||
      !valuesFirstPage ||
      !valuesFirstPage.experts ||
      valuesFirstPage.experts.length === 0
    ) {
      return { initialValues: {}, validationSchema: Yup.object() };
    }
    const expertTriggers = calculateExpertTriggers(valuesFirstPage.experts, branchSelectionValues);
    const documentTriggerIds = calculateDocumentTriggers(installerEntry, branchSelectionValues);

    const initialValues = createInitialValues(
      valuesFirstPage.experts,
      documentTriggerIds,
      expertTriggers
    );

    const validationSchema = createValidationSchema(
      initialValues,
      installerEntry,
      expertTriggers,
      valuesFirstPage.experts,
      branchSelectionValues
    );

    return {
      initialValues,
      validationSchema,
    };
  }, [valuesFirstPage, installerEntry, branchSelectionValues]);
};
