export const TENANT_ID = {
  EDI: 'EDI',
  SHNG: 'SHNG',
  AVA: 'AVA',
  BAG: 'BAG',
};
export const FORM_CONSTANTS = {
  IMAGE_SIZE: 40,
  NE: 'NE',
  DEB: 'DEB',
  TAGS_EVENTS: {
    STROM: 'Strom',
    GAS: 'Gas',
    NEU: 'Neu',
    ONLINE: 'Online',
    KOSTENPFLICHTIG: 'Kostenpflichtig',
  },

  FREETEXT_INPUT: 500,
  ACCEPTED_FORM_INPUT_FILE_TYPES: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'application/pdf',
  ],
  MAX_UPLOAD_FILE_COUNT: 10,
  MAX_UPLOAD_TOTAL_FILE_SIZE: 5000000,
};
export const STORAGE_KEY = {
  TENANT_ID: 'clientID',
  JWT: 'efix-token',
  FORM_DEBUG: 'form-debug',
  IAM: 'IAM',
};

export const TAGS_ARTICLES = {
  STROM: 'Strom',
  GAS: 'Gas',
  NEU: 'Neu',
};
export const OVERVIEW_LIST_TYPES = {
  event: 'event',
  article: 'article',
};
export const AEM_PAGE_TEMPLATE = {
  PUBLIC_PAGE: 'publicpage',
  CONTENT_PAGE: 'contentpage',
  HOMEPAGE: 'homepage',
  REACT_APP: 'react-app',
  ARTICLE: 'article',
  EVENT: 'event',
};
export const EIV_ENTRY_TYPE = {
  MAIN: 1,
  GUEST: 2,
};

export const EIV_ENTRY_TYPE_TO_GERMAN = {
  [EIV_ENTRY_TYPE.MAIN]: 'Stammeintrag',
  [EIV_ENTRY_TYPE.GUEST]: 'Gasteintrag',
};

// Note: status 5 and 6 are FE specific, not coming from EIV
export const EIV_STATUS = {
  NOT_SET: null,
  ARCHIVED: 1,
  INACTIVE: 2,
  IN_PROGRESS: 3,
  ACTIVE: 4,
  NEW: 5,
  DELETED: 6,
};
export const EIV_STATUS_ALLOWED_TO_REGISTER_EXISTING_EMAIL = [null, 1];
export const EIV_STATUS_TO_SHOW = [2, 3, 4, 5, 6];

export const EIV_STATUS_TO_STRING = {
  null: 'Nicht vorhanden',
  1: 'Archiviert',
  2: 'Inaktiv',
  3: 'In Bearbeitung',
  4: 'Aktiv',
  5: 'Neu hinzugefügt',
  6: 'Gelöscht',
};
export const PFLEGENDE_BU = {
  [TENANT_ID.EDI]: {
    EDI: '2236',
  },
  [TENANT_ID.SHNG]: {
    SHNG: '20952',
    EENG: '70072',
    NNNG: '1577',
    HANSG: '402',
  },
  [TENANT_ID.AVA]: {
    AVA: '2235',
    WEVG: '20008',
  },
  [TENANT_ID.BAG]: {
    BAG: '20288',
  },
};

export const PFLEGENDE_BU_ID_TO_NAME = {
  2236: 'E.DIS Netz GmbH',
  20952: 'Schleswig-Holstein Netz GmbH',
  70072: 'Schleswig-Holstein Netz GmbH',
  1577: 'Schleswig-Holstein Netz GmbH',
  402: 'Schleswig-Holstein Netz GmbH',
  2235: 'Avacon Netz GmbH',
  20008: 'Avacon Netz GmbH',
  20288: 'Bayernwerk Netz GmbH',
};
export const TENANT_TO_NAME = {
  [TENANT_ID.EDI]: 'E.DIS Netz GmbH',
  [TENANT_ID.SHNG]: 'Schleswig-Holstein Netz GmbH',
  [TENANT_ID.AVA]: 'Avacon Netz GmbH',
  [TENANT_ID.BAG]: 'Bayernwerk Netz GmbH',
};

export const EIV_OPERATING_MODE = {
  MAIN: 1,
  MAIN_AS_SIDE: 2,
  SIDE: 3,
  SIDE_AS_SIDE: 4,
  AUXILIARY: 5,
};
export const EXPERT_EDITING_STATUS = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};
export const RESEAL_ALLOWED_CONTRACTS_IDS = {
  DEVELOPMENT: [1, 54],
  STAGING: [1, 54],
  UAT: [1, 54],
  PRODUCTION: [1, 52],
};

export const KEYS_TO_GERMAN = {
  companyName1: 'Firmenname1',
  companyName2: 'Firmenname2',
  companyName3: 'Firmenname3',
  companyOwner: 'Firmeninhaber',
  companyStreet: 'Straße',
  companyHouseNumber: 'Hausnummer',
  companyPostal: 'PLZ',
  companyCity: 'Ort',
  companyRegion: 'Ortsteil',
  mobile: 'Mobil',
  phone: 'Telefon',
  craftsChamberArea: 'Hwk-Bereich',
  craftsChamberNumber: 'Hwk-Nummer',
  craftsChamberDate: 'Hwk-Datum',
  businessRegistrationLocation: 'Gewerbeanmeldung Ort',
  businessRegistrationDate: 'Gewerbeanmeldung Datum',
  commercialRegisterNumber: 'HRA/B Nummer',
  commercialRegisterDate: 'Letzte Änderung (Register)',
  gridProviderEnergy: 'Netzbetreiber Strom',
  foreignCompanyIdEnergy: 'Ausweisnummer Fremd Strom',
  gridProviderGas: 'Netzbetreiber Gas',
  foreignCompanyIdGas: 'Ausweisnummer Fremd Gas',
};

export const BAG_KEYS = [
  'craftsChamberArea',
  'craftsChamberNumber',
  'craftsChamberDate',
  'businessRegistrationLocation',
  'businessRegistrationDate',
  'commercialRegisterNumber',
  'commercialRegisterDate',
  'companyOwner',
];

export const BRANCH_TYPE = {
  GAS: 'GAS',
  ENERGY: 'ENERGY',
};
export const BRANCH_TYPE_TO_VARIABLE_NAME = {
  [BRANCH_TYPE.GAS]: 'Gas',
  [BRANCH_TYPE.ENERGY]: 'Energy',
};
export const INSTALLER_ENTRIES = {
  MAIN_ENERGY_ONLY: 'mainEnergyOnly',
  MAIN_GAS_ONLY: 'mainGasOnly',
  GUEST_ONLY: 'guestOnly',
  MAIN_BOTH: 'mainBoth',
  MAIN_ENERGY_GUEST_GAS: 'mainEnergyGuestGas',
  GUEST_ENERGY_MAIN_GAS: 'guestEnergyMainGas',
};

export const BRANCH_TYPE_TO_GERMAN_NAME = {
  [BRANCH_TYPE.GAS]: 'Gas',
  [BRANCH_TYPE.ENERGY]: 'Strom',
};

export const EIV_STATUS_TO_GERMAN_NAME = {
  [EIV_STATUS.ACTIVE]: 'Aktiv',
  [EIV_STATUS.INACTIVE]: 'Inaktiv',
  [EIV_STATUS.IN_PROGRESS]: 'In Bearbeitung',
};
export const EIV_STATUS_ALLOW_RENDER = [
  EIV_STATUS.ACTIVE,
  EIV_STATUS.INACTIVE,
  EIV_STATUS.IN_PROGRESS,
];
export const EIV_ENTRY_TYPE_TO_GERMAN_NAME = {
  main: 'Stamm',
  guest: 'Gast',
};
export const NE_ACTIVE_STEP = {
  EMAIL_INPUT: 0,
  BRANCH_SELECTION: 1,
  FORM: 2,
  FORM_UPLOAD: 3,
  RESULT: 4,
};
// Has to be in sync with env variables
export const STAGE = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const DOMAIN_PREFIX = {
  LOCAL: 'dev.',
  DEVELOPMENT: 'dev.',
  STAGING: 'qa.',
  PRODUCTION: '',
};

export const DOMAIN_CIAM_LOGIN_PREFIX = {
  LOCAL: 'login-dev.',
  DEVELOPMENT: 'login-dev.',
  STAGING: 'login-qa.',
  PRODUCTION: 'login.',
};

export const UPLOAD_RESTRICTION_ERROR_MESSAGE = {
  TOTAL_FILE_SIZE: 'Die Gesamtgröße der Dateien darf 5 MB nicht überschreiten.',
  TOTAL_COUNT: 'Es dürfen maximal 10 Dateien hochgeladen werden.',
};

export const VALIDATION_CONSTANTS = {
  TOO_LONG: 'Zu lang',
  MANDATORY: 'Pflichtfeld',
  TOO_SHORT: 'Zu kurz',
  INVALID_EMAIL: 'Keine gültige E-Mail',
  INVALID_POSTAL: 'Keine gültige PLZ',
};

export const EIV_CHAR_LENGTH = {
  STANDARD_TEXT: 100,
  EMAIL: 64,
  FIRMENNAME1: 60,
  FIRMENNAME2: 60,
  FIRMENNAME3: 60,
  INHABER: 60,
  BUERO_ORTSTEIL: 32,
  BUERO_BUNDESLAND: 32,
  BUERO_LAND: 32,
  COMMUNICATION_EMAIL: 256,
  BUERO_STRASSE: 100,
  BUERO_HAUSNUMMER: 100,
  BUERO_PLZ: 100,
  BUERO_ORT: 100,
  TELEFON_GESCHAEFT: 100,
  TELEFON_WERKSTATT: 100,
  TELEFON_MOBIL: 100,
  B_NETZBETREIBER_NAME: 400,
  AUSWEISNR_FREMD: 160,
  EXPERT_NAME: 32,
};

export const STATIC_FIELDS = [
  'experts',
  'documentsTrigger',
  'expertTrigger',
  'changes',
  'isAddressPublic',
  'canSendMail',
];
export const NINTY_DAYS_IN_MINUTES = 129600;
