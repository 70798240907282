export const edisEnergyGuestInstallerData = {
  anredename: null,
  ausw_gas_datum: '2020-12-04',
  ausw_gas_gueltig: '2030-12-31',
  ausw_probe_gas: 0,
  ausw_probe_strom: 0,
  ausw_probe_wasser: 0,
  ausw_strom_datum: '2020-12-04',
  ausw_strom_gueltig: '2027-12-31',
  ausw_strom_matrizennr: null,
  ausw_wasser_datum: null,
  ausw_wasser_gueltig: null,
  ausweisnr_fremd_gas: 'Gas_ID_extern',
  ausweisnr_fremd_strom: 'Energy_ID_extern',
  ausweisnr_fremd_wasser: null,
  b_anrede: null,
  b_betriebsart_gas: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_strom: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_wasser: null,
  b_briefanrede: {
    id: 2,
    name: 'Sehr geehrter Herr',
    geloescht: 1,
  },
  b_dezentrale_einheit_gas: null,
  b_dezentrale_einheit_strom: null,
  b_dezentrale_einheit_wasser: null,
  b_eintragung_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_gas_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_wasser_als: null,
  b_hwkbereich: {
    id: 180,
    name: 'HWK Berlin',
    geloescht: 0,
  },
  b_netzbetreiber_gas: {
    id: 35,
    name: 'E.DIS Netz GmbH',
    geloescht: 0,
    ort: 'Fürstenwalde',
    plz: '15517',
    strasse: 'Langewahler Str. 60',
    netzbetreibernummer: 1000,
    eintragungsart: 1,
  },
  b_netzbetreiber_strom: {
    id: 132,
    name: 'Netzgesellschaft Potsdam GmbH (NGP)',
    geloescht: 0,
    ort: 'Potsdam',
    plz: '14480',
    strasse: 'Steinstraße 104-106',
    netzbetreibernummer: 792,
    eintragungsart: 2,
  },
  b_netzbetreiber_wasser: null,
  b_standort: null,
  bemerkung: null,
  besichtigung_ausschuss: 'BzIA_x123A',
  besichtigung_datum: '2023-05-25',
  besichtigung_eon: 'Gumztest',
  betriebshaftpflicht: 1,
  buero_hausnummer: '12',
  buero_land: null,
  buero_ort: 'Werder',
  buero_ortsteil: 'Derwitz',
  buero_plz: '1454200',
  buero_strasse: 'Am Berliner Ring',
  communication_email: null,
  eintragungsart_erg_gas: null,
  eintragungsart_erg_strom: null,
  eintragungsart_erg_wasser: null,
  eintragungsart_gas: 1,
  eintragungsart_strom: 2,
  eintragungsart_wasser: null,
  email: 'efix.eon@gmail.com',
  ersteintragung: '2020-12-04',
  ersteintragung_gas: '2021-01-01',
  ersteintragung_wasser: null,
  fax: null,
  fax_werkstatt: null,
  firmeninhaber: 'Max_FIrmeninhaber',
  firmenname1: 'Testuser e.fix Elektro- und Gasinstallateur GmbH&CO.KG (QA)',
  firmenname2: null,
  firmenname3: 'Line 3 content',
  geloescht_am: null,
  geloescht_gas_am: null,
  geloescht_wasser_am: null,
  gewerbeanmeldung_datum: '2023-05-07',
  gewerbeanmeldung_ort: 'business_reg_Braunschweig',
  hrabnummer: 'HRA/b-Nr_test',
  hwkdatum: '2023-05-17',
  hwknummer: 'HWK nummer',
  id: 100000301755,
  importiert: null,
  in_verlaengerung: false,
  instv_gas_am: null,
  instv_wasser_am: null,
  lfdnr: null,
  musteranlage_gas: null,
  netzportal: 1,
  pflegende_bu: '2236',
  referenznummer_gas: null,
  referenznummer_strom: null,
  referenznummer_wasser: null,
  register_letzte_aenderung: '2023-05-01',
  registergericht: 'Feld_Registergericht',
  richtlinien: null,
  richtlinien_version: null,
  status_gas: 1,
  status_gas_automatic: 0,
  status_strom: 4,
  status_strom_automatic: 1,
  status_wasser: null,
  status_wasser_automatic: 0,
  telefon_geschaeft: '+49 33207 532-215',
  telefon_mobil: null,
  telefon_werkstatt: null,
  werkstatt_hausnummer: null,
  werkstatt_land: null,
  werkstatt_ort: null,
  werkstatt_ortsteil: null,
  werkstatt_plz: null,
  werkstatt_strasse: null,
  zaehlermontage_gas: '00',
  zaehlermontage_strom: '01',
  zu_adressveroeff: 'Nein',
  zu_gasinfo: null,
  zu_infoversand: 'Ja',
  zu_kommunikation_mail: null,
  zu_netzanschlussportal: null,
  zu_technikinfo: null,
};

export const edisEnergyGuestFormData = {
  companyName1: 'Testuser e.fix Elektro- und Gasinstallateur GmbH&CO.KG (QA)',
  companyName2: '',
  companyName3: 'Line 3 content',
  companyOwner: 'Max_FIrmeninhaber',
  companyId: '100000301755',
  companyStreet: 'Am Berliner Ring',
  companyHouseNumber: '12',
  companyPostal: '1454200',
  companyCity: 'Werder',
  companyRegion: 'Derwitz',
  gridProviderEnergy: 'Netzgesellschaft Potsdam GmbH (NGP)',
  gridProviderGas: 'E.DIS Netz GmbH',
  foreignCompanyIdEnergy: 'Energy_ID_extern',
  foreignCompanyIdGas: 'Gas_ID_extern',
  email: 'efix.eon@gmail.com',
  phone: '+49 33207 532-215',
  mobile: '',
  isAddressPublic: false,
  canSendMail: true,
  limitationGas: '',
  limitationEnergy: '',
  installerGasValidityDate: '31.12.2030',
  installerEnergyValidityDate: '31.12.2027',
  energyAgreement: false,
  gasAgreement: false,
  energyExtension: false,
  gasExtension: false,
  craftsChamberArea: 'HWK Berlin',
  craftsChamberNumber: 'HWK nummer',
  craftsChamberDate: '17.05.2023',
  businessRegistrationLocation: 'business_reg_Braunschweig',
  businessRegistrationDate: '07.05.2023',
  commercialRegisterNumber: 'HRA/b-Nr_test Feld_Registergericht',
  commercialRegisterDate: '01.05.2023',
  experts: [
    {
      firstName: 'Combi',
      lastName: 'Gas-Strom',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 289383,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Inaktiv',
      lastName: 'Inaktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 2,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290157,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Stromer',
      lastName: 'befristet',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290158,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gasaktiv',
      lastName: 'Strominaktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290159,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Stromer',
      lastName: 'aktiv-no-birth-date',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290160,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gasinaktiv',
      lastName: 'Stromaktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 2,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290161,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Eduard',
      lastName: 'Electricity-inaktiv',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '15.05.1940',
      statusGas: null,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290568,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'StromerFK',
      lastName: 'gelöscht-deleted',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: 1,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290569,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Jens',
      lastName: 'Gasman',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290570,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'gasfachkraft',
      lastName: 'befristet',
      expertGasValidityDate: '23.12.2024',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290571,
      isMainGas: false,

      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas',
      lastName: 'inactive',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '25.04.2015',
      statusGas: 2,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290572,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gaser',
      lastName: 'gelöscht-deleted',
      expertGasValidityDate: '31.12.2030',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '18.08.2025',
      statusGas: 1,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290573,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
  ],
  doesEnergyBranchExist: true,
  doesGasBranchExist: false,
  doesNeedAvaGuestPostal: false,
  avaGuestPostal: '',
  contracts: [
    {
      id: 5488,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '01.02.2024',
      cancelledOn: '',
    },
    {
      id: 5489,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'EBY - Nachplombierung Neusystem ab 01/2007',
      },
      validFrom: '01.02.2007',
      cancelledOn: '',
    },
    {
      id: 5490,
      contractStatus: 'Inaktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '01.02.2017',
      cancelledOn: '',
    },
    {
      id: 5491,
      contractStatus: 'Inaktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '06.02.2023',
      cancelledOn: '',
    },
    {
      id: 5492,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'BAG - Nachplombierung (Neusystem ab 2021)',
      },
      validFrom: '02.03.2022',
      cancelledOn: '',
    },
    {
      id: 5493,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'BAG - Nachplombierung (Neusystem ab 2021)',
      },
      validFrom: '03.01.2023',
      cancelledOn: '',
    },
    {
      id: 5494,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'BAG - Nachplombierung (Neusystem ab 2021)',
      },
      validFrom: '05.02.2021',
      cancelledOn: '',
    },
    {
      id: 5495,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '01.02.2024',
      cancelledOn: '',
    },
  ],
  seals: [
    {
      id: 18330,
      sealNumber: 'BAG USer Lost Seal',
      status: {
        name: 'verloren',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '04.12.2023',
      returnedOn: '',
      lostOn: '12.02.2024',
    },
    {
      id: 18331,
      sealNumber: 'BAG Seal returned',
      status: {
        name: 'zurückgegeben',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '02.03.2020',
      returnedOn: '12.02.2024',
      lostOn: '',
    },
    {
      id: 18332,
      sealNumber: 'BAG Seal aktive',
      status: {
        name: 'ausgegeben',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '09.03.2023',
      returnedOn: '',
      lostOn: '',
    },
    {
      id: 18333,
      sealNumber: 'BAG USer status unknown?',
      status: {
        name: 'unbekannt',
      },
      lostDate: '',
      lostReason: '',
      issuedOn: '07.08.2023',
      returnedOn: '',
      lostOn: '',
    },
  ],
};
